import CommonIcons from "@base/assets/icons";
import { categories, optionGender } from "@base/constants";
import useParseOption from "@base/hooks/useParseOptions";
import useGetPromotionList from "@base/modules/promotion/hooks/useGetPromotionList";
import IFilterPromotion from "@base/modules/promotion/interface/IFilterPromotion.interface";
import { IPromotion } from "@base/modules/promotion/interface/promotion.interface";
import useGetOneSiteSpecialPackage from "@base/modules/siteSpecialPackage/hooks/useGetOneSiteSpecialPackage";
import useGetListByUserKey from "@base/modules/user/profile/hooks/useGetListByUserKey";
import { IPatientRecordData } from "@base/modules/user/profile/profile.interface";
import { baseRoutes } from "@base/routes/baseRoutes";
import { initialPage } from "@base/themes/colorTheme";
import CommonStyles from "@components/CommonStyles";
import Payment from "@components/Payment";
import { Divider } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useCalCulateFee from "@base/modules/patientSpecialPackage/hooks/useCalculateFee";
import useBuySpecialPackage from "@base/modules/patientSpecialPackage/hooks/useBuySpecialPackage";
import orderService from "@base/modules/order/orderService";
import useGetUserInfo from "@base/modules/user/account/hooks/useGetUserInfo";
import { IPayloadOrder } from "@base/modules/order/interface/IPayloadOrder";
import useOrder from "@base/modules/order/hooks/useOrder";
import useCheckLogin from "@base/hooks/useCheckLogin";
import { responseUI } from "@base/themes/response";
import useGetPromotionByType from "@base/modules/promotion/hooks/useGetPromotionByType";
import IFilterPromotionByType from "@base/modules/promotion/interface/IFilterPromotionByType.interface";
import CardDiscount from "@components/CommonStyles/CardDiscount";
import useToggle from "@base/hooks/useToggle";
import ModalListDiscount from "@components/AppointmentBooking/ModalStep3/ModalListDiscount";
import ModalVerifyUseCode from "@components/AppointmentBooking/ModalStep3/ModalVerifyUseCode";
import ModalVerifyUseCodePackage from "./ModalConfirm/ModalVerifyUseCodePackage";
import ModalCreateUser from "@components/AppointmentBooking/ModalStep3/ModalCreateUser";
import { BankCode, ECurrency, EOrderType } from "@base/enums";
import useGetSiteByCode from "@base/modules/site/hooks/useGetSiteByCode";
import { useDispatch } from "react-redux";
import { setAlertMessage } from "@base/store/reducers/appCommonSlice";

export default function PaymentPackageService() {
  //! state
  const breadcrumItem = [
    {
      href: baseRoutes.home,
      title: <CommonIcons.IconHome />,
    },
    {
      title: "Gói dịch vụ",
      href: baseRoutes.bookingPackage,
    },
    {
      title: "Mua gói dịch vụ",
    },
  ];
  const { handleCheckLogin } = useCheckLogin();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const site_code = searchParams.get("site_code");
  const package_code = searchParams.get("package_code");
  const { getUserInfoData, handleGetUserInfo } = useGetUserInfo();
  const { data: site } = useGetSiteByCode(String(site_code));
  const { open: openSeeMore, shouldRender: shouldRenderSeeMore, toggle: toggleSeeMore } = useToggle();
  const { open: openVerifyUseCode, shouldRender: shouldRenderVerifyUseCode, toggle: toggleVerifyUseCode } = useToggle();
  const { open: openModalCreate, shouldRender: shouldRenderCreate, toggle: toggleCreate } = useToggle();
  const { open: openConfirmTransfer, shouldRender: shouldRenderConfirmTransfer, toggle: toggleConfirmTransfer } = useToggle();
  const filterPromotion: IFilterPromotion = {
    lang: "vi",
    category: categories.PACKAGE,
    site_code: String(site_code),
  };
  const { data: dataPromotion, isLoading: loadingPromotion } = useGetPromotionList(filterPromotion, "OFFLINE");
  const { data: dataSiteSpecialPackage, isLoading } = useGetOneSiteSpecialPackage({
    site_code: String(site_code),
    package_code: String(package_code),
  });
  const detailSiteSpecialPackage = dataSiteSpecialPackage?.data;
  const { data, isLoading: loadingUser, refetch } = useGetListByUserKey();
  const [userService, setUserService] = useState<IPatientRecordData>();
  const [promotionSelected, setPromotionSelected] = useState<{
    actual_fee: number;
    discount: number;
    other_fee: number;
    total_fee: number;
    tax: number;
  }>();
  const [methodPayment, setMethodPayment] = useState(null);
  const { handleFee, isLoading: loadingCalculate } = useCalCulateFee();
  const { handleBuy } = useBuySpecialPackage();
  const { resultOrder, error, handleOrder } = useOrder("vi");
  const [discountCode, setDiscountCode] = useState("");
  const filterPromotionByType: IFilterPromotionByType = {
    lang: "vi",
    for_check: true,
    site_code: String(site_code),
    category: categories.PACKAGE,
    package_code: String(package_code),
  };
  const {
    data: dataDiscount,
    isLoading: loadingCheckDiscount,
    refetch: refetchCheck,
  } = useGetPromotionByType(filterPromotionByType, discountCode, false);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>();

  //! function
  const handleChangeOption = (value: any) => {
    const userSelected = data?.data && data.data.find((item) => item.patient_record?.code === value);
    if (userSelected) {
      setUserService(userSelected);
    }
  };

  const handleChangePromotion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueCode = event.target.value;
    setDiscountCode(valueCode);
  };

  const handleChangeMethodPayment = (value: any) => {
    setMethodPayment(value);
  };

  const getGender = optionGender.find((item) => item.value === userService?.patient_record?.patient_gender);

  const parseOption = useCallback(() => {
    if (!data?.data) {
      return [];
    } else {
      return data.data.map((item) => {
        return { label: item.patient_record?.patient_name, value: item.patient_record?.code };
      });
    }
  }, [loadingUser]);

  useEffect(() => {
    const getOptionDefault = data?.data && data.data?.find((item) => item.patient_record?.default_record === true);
    if (getOptionDefault) {
      setUserService(getOptionDefault);
    }
  }, [loadingUser]);

  useEffect(() => {
    handleCheckLogin();
    handleGetUserInfo();
  }, []);

  useEffect(() => {
    const calculateFee = async () => {
      const result = await handleFee(bodyFee);
      setPromotionSelected(result?.data);
    };
    if (dataSiteSpecialPackage && userService) {
      calculateFee();
    }
  }, [isLoading, userService]);

  useEffect(() => {
    if (dataDiscount?.data && !loadingCheckDiscount) {
      toggleVerifyUseCode();
    }
  }, [loadingCheckDiscount]);

  const bodyFee = {
    amount: detailSiteSpecialPackage?.amount,
    discount_amount: detailSiteSpecialPackage?.discount_amount,
    other_fee: 0,
    package_code: package_code,
    package_name: detailSiteSpecialPackage?.name,
    patient_record: userService?.patient_record,
    site: detailSiteSpecialPackage?.site,
    patient_special_package_promotions: promotionSelected ? [promotionSelected] : [],
  };

  const handleBuyAndOrder = async () => {
    setIsLoadingPage(true);
    const result = await handleBuy(bodyFee);
    if (result?.data) {
      const body: IPayloadOrder = {
        bank_code: methodPayment,
        cancel: false,
        created_time: 0,
        customer_address: String(userService?.patient_record?.patient_address),
        customer_email: String(userService?.patient_record?.patient_email),
        customer_name: String(userService?.patient_record?.patient_name),
        customer_phone: String(getUserInfoData?.user_name),
        deliveryFee: 0,
        description: `${detailSiteSpecialPackage?.name}`,
        discount_amounts: promotionSelected?.discount,
        order_time: 0,
        order_type: categories.PACKAGE,
        real_amounts: Number(promotionSelected?.total_fee),
        ref_key: result.data.key,
        refund_amounts: 0,
        site_code: String(site_code),
        site_name: String(detailSiteSpecialPackage?.site.site_state_name),
        total_amounts: promotionSelected?.actual_fee,
      };
      await handleOrder(body);
    }

    setIsLoadingPage(false);
  };

  useEffect(() => {
    if (!resultOrder) {
      return;
    }

    dispatch(setAlertMessage({ type: "success", message: "Thông báo", description: "Thanh toán thành công!" }));

    if (methodPayment == BankCode.BANK_TRANSFER) {
      toggleConfirmTransfer();
      return;
    }

    if (methodPayment == BankCode.CASH) {
      setTimeout(() => {
        redirectToResult();
      }, 1000); // alert msg
    }
  }, [resultOrder]);

  useEffect(() => {
    if (error) {
      dispatch(setAlertMessage({ type: "error", message: "Thông báo", description: "Thanh toán không thành công!" }));
    }
  }, [error]);

  const redirectToResult = () => {
    if (resultOrder?.order_type == EOrderType.APPT && resultOrder?.ref_key) {
      location.replace(`${baseRoutes.userAppointmentDetail.replace(":key", resultOrder?.ref_key)}`);
      return;
    }

    if (resultOrder?.order_type == EOrderType.PACKAGE && resultOrder?.ref_key) {
      location.replace(`${baseRoutes.userPackageDetail.replace(":key", resultOrder?.ref_key)}`);
      return;
    }
  };

  const onConfirmTransferInformation = () => {
    toggleConfirmTransfer();

    requestAnimationFrame(() => {
      redirectToResult();
    });
  };

  const handleCheckDiscount = async () => {
    refetchCheck();
  };

  //! render
  const seeMoreDiscount = () => {
    return dataPromotion?.data.slice(0, 2).map((item, index) => {
      const isChecked = item.type === discountCode;
      return <CardDiscount key={index} data={item} isChecked={isChecked} setDiscountCode={setDiscountCode} />;
    });
  };

  const renderExtraAction = () => {
    return (
      <CommonStyles.TypographyUI onClick={toggleCreate} className="!text-common-mainColor text-base cursor-pointer">
        Tạo hồ sơ
      </CommonStyles.TypographyUI>
    );
  };
  return (
    <div>
      <div className="bg-common-white">
        <div className={`h-60 pb-8 relative ${responseUI.screen} py-5`}>
          <div className="text-center mt-10">
            <CommonStyles.TypographyUI use="title" level={3} className={`${responseUI.text.title}`}>
              Mua gói dịch vụ
            </CommonStyles.TypographyUI>
            <CommonStyles.TypographyUI use="paragraph" className="text-common-subtext text-xs lg:text-base">
              Đặt lịch khám- chữa bệnh tại 365 Medihome giúp chủ động thời gian, hạn chế tiếp xúc đông người
            </CommonStyles.TypographyUI>
          </div>

          <div className="absolute right-0 bottom-0">
            <CommonIcons.IconHeart width={150} height={150} />
          </div>
        </div>
      </div>

      <div className={`grid grid-cols-1 lg:grid-cols-2 gap-8 py-8 ${responseUI.screen}`}>
        <div className="bg-common-white rounded-2xl p-8">
          <CommonStyles.TypographyUI className="text-base lg:text-lg font-semibold text-common-maintext">Mua gói dịch vụ</CommonStyles.TypographyUI>
          <Divider />

          <div className="flex flex-col gap-3">
            <CommonStyles.TypographyUI className="text-sm lg:text-base font-semibold text-common-maintext">Chi tiết</CommonStyles.TypographyUI>

            <div className="flex justify-between items-center gap-20">
              <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Gói dịch vụ</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base text-common-maintext font-medium">
                {detailSiteSpecialPackage?.name}
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex justify-between items-center gap-20">
              <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Cơ sở y tế</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base text-common-maintext font-medium">
                {detailSiteSpecialPackage?.site.name}
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex justify-between items-center gap-20">
              <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Địa chỉ</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base text-common-maintext font-medium">
                {detailSiteSpecialPackage?.site?.address}
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex justify-between items-center gap-20">
              <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Giá</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="basis-2/3 text-end text-sm lg:text-base !text-common-inprogress font-medium">
                {detailSiteSpecialPackage?.amount.toLocaleString()}
                {ECurrency.VND}
              </CommonStyles.TypographyUI>
            </div>
          </div>
          <Divider />

          <div className="flex flex-col gap-3">
            <CommonStyles.TypographyUI className="text-base lg:text-lg font-semibold text-common-maintext">
              Thông tin khách hàng
            </CommonStyles.TypographyUI>

            <CommonStyles.SelectUI
              label="Hồ sơ"
              value={userService?.patient_record?.code}
              onChange={handleChangeOption}
              placeholder="Chọn hồ sơ"
              options={parseOption()}
              variant="filled"
              extraAction={renderExtraAction}
            />

            <div className="flex justify-between items-center">
              <CommonStyles.TypographyUI className="basis-1/3 lg:text-base text-common-subtext">Họ và tên</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="basis-2/3 text-end lg:text-base text-common-maintext font-medium">
                {userService?.patient_record?.patient_name}
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex justify-between items-center">
              <CommonStyles.TypographyUI className="basis-1/3 lg:text-base text-common-subtext">Giới tính</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="basis-2/3 text-end lg:text-base text-common-maintext font-medium">
                {getGender?.label}
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex justify-between items-center">
              <CommonStyles.TypographyUI className="basis-1/3 lg:text-base text-common-subtext">Ngày sinh</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="basis-2/3 text-end lg:text-base text-common-maintext font-medium">
                {String(userService?.patient_record?.patient_dob ?? "")}
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex justify-between items-center">
              <CommonStyles.TypographyUI className="basis-1/3 lg:text-base text-common-subtext">Địa chỉ</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="basis-2/3 text-end lg:text-base text-common-maintext font-medium">
                {userService?.patient_record?.patient_address}
              </CommonStyles.TypographyUI>
            </div>

            <div className="flex justify-between items-center">
              <CommonStyles.TypographyUI className="basis-1/3 lg:text-base text-common-subtext">Email</CommonStyles.TypographyUI>
              <CommonStyles.TypographyUI className="basis-2/3 text-end lg:text-base text-common-maintext font-medium">
                {userService?.patient_record?.patient_email}
              </CommonStyles.TypographyUI>
            </div>
          </div>
        </div>

        <div className="bg-common-white rounded-2xl p-8 flex flex-col justify-between">
          <div>
            <CommonStyles.TypographyUI className="text-base lg:text-lg font-semibold text-common-maintext">Thanh toán</CommonStyles.TypographyUI>
            <Divider />

            <div className="flex flex-col gap-3">
              <CommonStyles.TypographyUI className="text-sm lg:text-base font-semibold text-common-maintext">Thông tin </CommonStyles.TypographyUI>

              <CommonStyles.InputUI
                label="Mã giảm giá:"
                value={discountCode}
                onChange={handleChangePromotion}
                variant="filled"
                placeholder="Nhập mã giảm giá"
                suffix={
                  <CommonStyles.ButtonUI
                    className="h-full"
                    onClick={() => {
                      handleCheckDiscount();
                    }}
                  >
                    Áp dụng
                  </CommonStyles.ButtonUI>
                }
              />
              <div className="flex justify-between">
                <CommonStyles.TypographyUI>Chọn mã giảm giá</CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI onClick={toggleSeeMore} className="!text-common-inprogress cursor-pointer">
                  Xem thêm
                </CommonStyles.TypographyUI>
              </div>
              <div className="grid grid-cols-2 gap-3">{seeMoreDiscount()}</div>

              <div className="flex justify-between items-center">
                <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Giá</CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI className="basis-2/3 text-sm lg:text-base text-end text-common-maintext font-medium">
                  {promotionSelected?.total_fee.toLocaleString()}
                  {ECurrency.VND}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex justify-between items-center">
                <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Giảm tiền</CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI className="basis-2/3 text-sm lg:text-base text-end text-common-maintext font-medium">
                  {promotionSelected?.discount.toLocaleString()}
                  {ECurrency.VND}
                </CommonStyles.TypographyUI>
              </div>

              <div className="flex justify-between items-center">
                <CommonStyles.TypographyUI className="basis-1/3 text-sm lg:text-base text-common-subtext">Tổng tiền</CommonStyles.TypographyUI>
                <CommonStyles.TypographyUI className="basis-2/3 text-sm lg:text-base text-end !text-common-inprogress font-medium">
                  {promotionSelected?.actual_fee.toLocaleString()}
                  {ECurrency.VND}
                </CommonStyles.TypographyUI>
              </div>
            </div>

            {Number(promotionSelected?.actual_fee) > 0 && (
              <>
                <Divider />
                <div className="flex flex-col gap-3">
                  <CommonStyles.TypographyUI className="text-sm lg:text-base font-semibold text-common-maintext">
                    Phương thức thanh toán:
                  </CommonStyles.TypographyUI>
                  <Payment setBankCode={handleChangeMethodPayment} listPayment={site?.data.supported_payment_methods} />
                </div>
              </>
            )}
          </div>

          <div>
            <Divider className="my-4 border-common-disable" />

            <div className="flex justify-end items-center">
              <div className="flex items-center gap-4">
                <CommonStyles.ButtonUI
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="h-12 font-medium text-base bg-common-orange100 text-common-orange hover:!bg-common-orange200 hover:!text-common-orange"
                  icon={<CommonIcons.IconArrowLeft />}
                  iconPosition="start"
                  disabled={isLoadingPage}
                >
                  Quay lại
                </CommonStyles.ButtonUI>

                <CommonStyles.ButtonUI
                  onClick={handleBuyAndOrder}
                  className="h-12 font-medium text-base"
                  disabled={methodPayment == null && Number(promotionSelected?.actual_fee) > 0}
                  icon={<CommonIcons.IconArrowRight />}
                  iconPosition="end"
                  loading={isLoadingPage}
                >
                  Thanh toán
                </CommonStyles.ButtonUI>
              </div>
            </div>
          </div>
        </div>
      </div>
      {shouldRenderVerifyUseCode && (
        <CommonStyles.ModalConfirmationUI
          title={<CommonStyles.TypographyUI className="!text-lg font-semibold">Tìm thấy mã giảm giá</CommonStyles.TypographyUI>}
          type="normal"
          open={openVerifyUseCode}
          toggle={toggleVerifyUseCode}
          content={
            <ModalVerifyUseCodePackage
              filterPromotionByType={filterPromotionByType}
              discount={dataDiscount?.data}
              toggle={toggleVerifyUseCode}
              setDiscount={setPromotionSelected}
              user={getUserInfoData}
              bodyFee={bodyFee}
            />
          }
          hiddenAction
        />
      )}

      {shouldRenderSeeMore && (
        <CommonStyles.ModalConfirmationUI
          title="Chọn mã giảm giá"
          width={700}
          type="normal"
          open={openSeeMore}
          toggle={toggleSeeMore}
          content={
            <ModalListDiscount type={categories.PACKAGE} setDiscountCode={setDiscountCode} discountCode={discountCode} toggle={toggleSeeMore} />
          }
          hiddenAction
        />
      )}

      {shouldRenderCreate && (
        <CommonStyles.ModalConfirmationUI
          width={800}
          type="normal"
          title="Tạo hồ sơ"
          toggle={toggleCreate}
          open={openModalCreate}
          content={<ModalCreateUser refetch={refetch} toggle={toggleCreate} />}
          hiddenAction
        />
      )}

      {shouldRenderConfirmTransfer && (
        <CommonStyles.TransferInformationDialog
          resultOrder={resultOrder}
          transferContent={resultOrder.description}
          toggle={onConfirmTransferInformation}
          open={openConfirmTransfer}
        />
      )}
    </div>
  );
}
